import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import { graphql, Link } from "gatsby"
import { ParallaxProvider, Parallax } from "react-scroll-parallax"

import Img from "gatsby-image"
//import Preloader from "../components/Preloader/Preloader"
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import { is_Array, sort_alphabetically, sort_chronologically } from "../utility"
import Cookies from "../components/Cookies/Cookies"

const Homepage = data => {
  /* const [imgCounter, setImgCounter] = useState(0)
  const [preloading, setPreloading] = useState(true) */
  const [artworks, setArtworks] = useState(null)
  const [sortChronologically, setSortChronologically] = useState(true)
  const [mobileVersion, setMobileVersion] = useState(false)
  const artworksData = data.data.allDirectusArtwork.edges

  const checkWindowWidth = () => {
    if (window.innerWidth < 1000) {
      setMobileVersion(true)
    } else if (window.innerWidth > 1001) {
      setMobileVersion(false)
    }
  }

  useEffect(() => {
    checkWindowWidth()
    window.addEventListener("resize", checkWindowWidth)

    return () => {
      window.removeEventListener("resize", checkWindowWidth)
    }
  }, [])

  useEffect(() => {
    let sortedArtworks

    /* if (localStorage.getItem("preloader") === "true") {
      setPreloading(false)
    }
    setTimeout(() => {
      setPreloading(false)
      window.localStorage.setItem("preloader", true)
    }, 6000) */

    if (localStorage.getItem("isAlphabetically") === "true") {
      sortedArtworks = sort_alphabetically(artworksData)
      setSortChronologically(false)
    } else {
      sortedArtworks = sort_chronologically(artworksData)
      const sortedSlugs = sortedArtworks.map(item => item.node.slug)
      window.localStorage.setItem("sortedSlugs", JSON.stringify(sortedSlugs))
      setSortChronologically(true)
    }

    setArtworks(sortedArtworks)
  }, [artworksData])

  const sortHandler = chronologically => {
    const sortedArray = chronologically
      ? sort_chronologically(artworks)
      : sort_alphabetically(artworks)

    window.scrollTo(0, 0)

    setSortChronologically(chronologically)

    setArtworks(sortedArray)

    const sortedSlugs = sortedArray.map(item => item.node.slug)
    window.localStorage.setItem("sortedSlugs", JSON.stringify(sortedSlugs))
    chronologically
      ? window.localStorage.setItem("isAlphabetically", false)
      : window.localStorage.setItem("isAlphabetically", true)
  }

  const artworkCSScheck = i => {
    if (i % 3 === 0) {
      return "right"
    } else if (i % 3 === 1) {
      return "center"
    } else if (i % 3 === 2) {
      return "left"
    }
  }

  // Parallax Alphabetically
  const artworkAnimX = i => {
    if (i % 28 === 0) {
      return [-20, 10]
    } else if (i % 28 === 1) {
      return [20, -20]
    } else if (i % 28 === 2) {
      return [-10, 25]
    } else if (i % 28 === 3) {
      return [10, -30]
    } else if (i % 28 === 4) {
      return [-10, 20]
    } else if (i % 28 === 5) {
      return [30, 0]
    } else if (i % 28 === 6) {
      return [-30, -5]
    } else if (i % 28 === 7) {
      return [30, -10]
    } else if (i % 28 === 8) {
      return [-10, 30]
    } else if (i % 28 === 9) {
      return [10, -30]
    } else if (i % 28 === 10) {
      return [10, 60]
    } else if (i % 28 === 11) {
      return [30, -10]
    } else if (i % 28 === 12) {
      return [-40, 0]
    } else if (i % 28 === 13) {
      return [30, -20]
    } else if (i % 28 === 14) {
      return [-10, 30]
    } else if (i % 28 === 15) {
      return [-20, -60]
    } else if (i % 28 === 16) {
      return [-15, 25]
    } else if (i % 28 === 17) {
      return [50, -10]
    } else if (i % 28 === 18) {
      return [-70, -20]
    } else if (i % 28 === 19) {
      return [20, -20]
    } else if (i % 28 === 20) {
      return [40, 130]
    } else if (i % 28 === 21) {
      return [-20, -60]
    } else if (i % 28 === 22) {
      return [0, 60]
    } else if (i % 28 === 23) {
      return [50, -10]
    } else if (i % 28 === 24) {
      return [-50, -10]
    } else if (i % 28 === 25) {
      return [30, -20]
    } else if (i % 28 === 26) {
      return [50, 100]
    } else if (i % 28 === 27) {
      return [-30, -70]
    } else if (i % 28 === 28) {
      return [0, 40]
    }
  }

  // Parallax Chronologically
  const artworkAnimXChronologically = i => {
    if (i % 28 === 0) {
      return [10, -30]
    } else if (i % 28 === 1) {
      return [0, 40]
    } else if (i % 28 === 2) {
      return [50, 0]
    } else if (i % 28 === 3) {
      return [-70, -20]
    } else if (i % 28 === 4) {
      return [40, -60]
    } else if (i % 28 === 5) {
      return [60, 110]
    } else if (i % 28 === 6) {
      return [-40, -100]
    } else if (i % 28 === 7) {
      return [-30, 30]
    } else if (i % 28 === 8) {
      return [70, 20]
    } else if (i % 28 === 9) {
      return [-30, 10]
    } else if (i % 28 === 10) {
      return [20, -20]
    } else if (i % 28 === 11) {
      return [30, 90]
    } else if (i % 28 === 12) {
      return [-40, -90]
    } else if (i % 28 === 13) {
      return [-20, 30]
    } else if (i % 28 === 14) {
      return [40, 0]
    } else if (i % 28 === 15) {
      return [-40, 0]
    } else if (i % 28 === 16) {
      return [30, -30]
    } else if (i % 28 === 17) {
      return [-10, 30]
    } else if (i % 28 === 18) {
      return [10, -40]
    } else if (i % 28 === 19) {
      return [-30, 20]
    } else if (i % 28 === 20) {
      return [30, -10]
    } else if (i % 28 === 21) {
      return [-150, -80]
    } else if (i % 28 === 22) {
      return [30, -20]
    } else if (i % 28 === 23) {
      return [20, 80]
    } else if (i % 28 === 24) {
      return [10, -40]
    } else if (i % 28 === 25) {
      return [-20, 50]
    } else if (i % 28 === 26) {
      return [40, 0]
    } else if (i % 28 === 27) {
      return [-30, 10]
    } else if (i % 28 === 28) {
      return [0, 0]
    }
  }

  /* useEffect(() => {
    console.log(imgCounter)
  }, [imgCounter]) */

  let showArtworks = <p></p>

  if (artworks === 404 || artworks === 500) {
    showArtworks = <p>Something went wrong</p>
  } else if (is_Array(artworks)) {
    showArtworks = artworks.map((artworks, i) => {
      return (
        <Parallax
          x={
            mobileVersion === true
              ? [0, 0]
              : sortChronologically === false
              ? artworkAnimXChronologically(i)
              : artworkAnimX(i)
          }
          y={[0, 0]}
          tagOuter="figure"
          key={i}
          styleOuter={
            mobileVersion === true
              ? null
              : artworks.node.viewport[0] === "portrait"
              ? { width: "450px" }
              : null
          }
          className={[
            "picture-container",
            artworks.node.viewport,
            artworkCSScheck(i),
          ].join(" ")}
        >
          <Link to={"/" + artworks.node.slug}>
            {artworks.node.secondary_image ? (
              <img
                src={artworks.node.secondary_image.data.full_url}
                className="picture-container__image"
                alt={artworks.node.year + " " + artworks.node.title}
              />
            ) : (
              <Img
                loading="eager"
                /* onStartLoad={item => {
                  setImgCounter(imgCounter => imgCounter + 1)
                }}
                onLoad={item => {
                  setImgCounter(imgCounter => imgCounter - 1)
                }} */
                fluid={artworks.node.image.localFile.childImageSharp.fluid}
                imgStyle={{ objectFit: "cover" }}
                className="picture-container__image"
                alt={artworks.node.year + " " + artworks.node.title}
              />
            )}
          </Link>
          {sortChronologically === true && (
            <div className="picture-container__text chronologically">
              <p className="picture-container__text-date">
                {artworks.node.year}
              </p>
              <p className="picture-container__text-title">
                {artworks.node.author} {artworks.node.surname}
                <br />
                {artworks.node.category}
              </p>
            </div>
          )}
          {sortChronologically === false && (
            <div className="picture-container__text alphabetically">
              <p className="picture-container__text-date">
                {artworks.node.author} {artworks.node.surname}
                {/* <br />
                {artworks.node.category} */}
              </p>
              <p className="picture-container__text-title">
                {artworks.node.year}
              </p>
            </div>
          )}
        </Parallax>
      )
    })
  }

  return (
    <>
      <Helmet>
        <html lang="cs" />
        <title>Magnus Art</title>
        <meta
          name="description"
          content="J&amp;T Banka vybudovala vlastní sbírku současného umění složenou z děl laureátů Ceny Jindřicha Chalupeckého."
        />
      </Helmet>
      {/* {preloading === true ? (
        <Preloader />
      ) : ( */}
      <ParallaxProvider>
        <Header {...data} sort={sortHandler} />
        <Cookies />
        <main className="homepage">{showArtworks}</main>
        <Footer />
      </ParallaxProvider>
    </>
  )
}

export const query = graphql`
  query MyQuery {
    allDirectusArtwork {
      edges {
        node {
          slug
          image {
            localFile {
              childImageSharp {
                fluid(
                  maxWidth: 900
                  maxHeight: 1024
                  srcSetBreakpoints: [960, 1920]
                  quality: 90
                  fit: INSIDE
                ) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                  presentationHeight
                  sizes
                  src
                  srcSet
                  presentationWidth
                  aspectRatio
                }
              }
            }
          }
          secondary_image {
            data {
              full_url
            }
          }
          author
          surname
          year
          title
          category
          viewport
        }
      }
    }
  }
`

export default Homepage
