export const is_Array = obj => {
  return !!obj && obj.constructor === Array
}

export const sort_chronologically = data => {
  let sortedChronologically = [...data].sort((a, b) => {
    return a.node.year < b.node.year ? -1 : 1
  })
  return sortedChronologically
}

export const sort_alphabetically = (data, lang) => {
  let sortedAlphabetically = [...data].sort((a, b) => {
    //return a.node.surname < b.node.surname ? -1 : 1
    return a.node.surname.localeCompare(b.node.surname, 'cs', {sensitivity: 'base'});
  })
  return sortedAlphabetically
}
